<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-5
          h1 ユーザー情報の編集
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label(for="status") ステータスバッジ
              br
              img#statusImg(src="" alt="" ref="statusImg" v-model="iconPath")
            .form-group
              label(for="nickname") 名前（サイト内の表示 最大20文字）
              input#nickname.form-control(type="text" placeholder="ヤマ　ラボ太" v-model="nickname" maxlength='20' required)
              .invalid-feedback お名前を入力してください
            .form-group
              label(for="gender") 性別
              br
              .form-check.form-check-inline    
                input#gender1.form-check-input(type="radio" v-model="gender" name="gender" value="男性" required) 
                label.form-check-label.radio-label(for="gender1") 男性 
              .form-check.form-check-inline
                input#gender2.form-check-input(type="radio" v-model="gender" name="gender" value="女性" required)
                label.form-check-label.radio-label(for="gender2") 女性
              .form-check.form-check-inline
                input#gender3.form-check-input(type="radio" v-model="gender" name="gender" value="未回答" required checked)
                label.form-check-label.radio-label(for="gender3") 未回答
            .form-group
              label(for="age") 年代
              Age(v-model="age")
            .form-group
              label(for="locale") 都道府県
              Pref(v-model="locale")
            .form-group
              div(for="isPrivate") ユーザー情報公開
                span.css-fukidashi
                  div.text.mx-3.px-1(@mouseover="show1 = true" @mouseleave="show1 = false") ？
                  transition(name="fade")
                    p.fukidashi(v-if="show1") 名前、登攀記録、チェックイン状況等の他ユーザーへの公開有無設定
            .custom-control.custom-checkbox 
              input#isPrivate.custom-control-input(type="checkbox" v-model="isPrivate")
              label.custom-control-label(for="isPrivate") ユーザー情報を公開する
            .form-group.ml-2
              .mt-3.ml-1(for="recordOpenRange") 登攀記録の公開範囲
                span.css-fukidashi
                  .text.mx-3.px-1(@mouseover="show2 = true" @mouseleave="show2 = false") ？
                  transition(name="fade")
                    p.fukidashi(v-if="show2") 登攀記録(登攀件数、どの岩場を登攀したか)の公開範囲設定
              .form-check.form-check-inline.mt-2.ml-2  
                input#recordOpenRange1.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="OPEN" required) 
                label.form-check-label.radio-label(for="recordOpenRange1") 公開 
              br
              .form-check.form-check-inline.ml-2
                input#recordOpenRange2.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="FRIENDS_ONLY" required)
                label.form-check-label.radio-label(for="recordOpenRange2") 友達のみ公開
              br
              .form-check.form-check-inline.mb-2.ml-2
                input#recordOpenRange3.form-check-input(type="radio" v-model="recordOpenRange" :disabled="!isPrivate" name="recordOpenRange" value="CLOSE" required)
                label.form-check-label.radio-label(for="recordOpenRange3") 非公開
            .form-group.ml-2
              div.mt-3.ml-1(for="checkinOpenRange") チェックイン情報の公開範囲
                span.css-fukidashi
                  div.text.mx-3.px-1(@mouseover="show3 = true" @mouseleave="show3 = false") ？
                  transition(name="fade")
                    p.fukidashi(v-if="show3") チェックイン情報（リアルタイム情報と履歴情報）の公開範囲設定
              .form-check.form-check-inline.mt-2.ml-2
                input#checkinOpenRange1.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="OPEN" required) 
                label.form-check-label.radio-label(for="checkinOpenRange1") 公開 
              br
              .form-check.form-check-inline.ml-2
                input#checkinOpenRange2.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="FRIENDS_ONLY" required)
                label.form-check-label.radio-label(for="checkinOpenRange2") 友達のみ公開
              br
              .form-check.form-check-inline.ml-2
                input#checkinOpenRange3.form-check-input(type="radio" v-model="checkinOpenRange" :disabled="!isPrivate" name="checkinOpenRange" value="CLOSE" required)
                label.form-check-label.radio-label(for="checkinOpenRange3") 非公開
            .form-group
              label(for="Inquiry") 自己紹介
              textarea#Inquiry.form-control(type="Inquiry" rows="5" maxlength="150" v-model="profileText")
            input.btn.btn-primary(type="submit" value="上記の内容で更新する" v-bind:disabled="isDisabled")
            .mt-5
              router-link(to="/unsubscribe/consent") ヤマラボを退会する
          b-modal#status-modal(title="アイコンを選択してください。" hide-footer)
            .text-center
              img.m-1(:src="materialURL + item.imgPath" @click="changeStatus(item.imgPath)" v-for="item in data")
</template>

<script>
import Pref from '@/components/user/pref.vue'
import Age from '@/components/user/age.vue'
import axios from 'axios'

import {
    Config,
    CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      name: '',
      nickname: '未設定',
      gender: '未回答',
      age: '未回答',
      locale: '未回答',
      message: '',
      isDisabled: false,
      isPrivate: '',
      checkinOpenRange: '',
      recordOpenRange: '',
      show1: false,
      show2: false,
      show3: false,
      errMessage: false,
      profileText: '',
      iconPath: '',
      data: null,
      materialURL: process.env.VUE_APP_MATERIAL_URL,
    }
  },
  mounted() {
    //サインインしていない場合
    this.$user.cognito.getUserAttributes((err, result) => {
      if (err) {
        this.$router.push({ name: 'login', query: { url:this.$route.path }}) //ページ移動
        return
      }
      //サインインしている場合、表示名の取得
      for (let i = 0; i < result.length; i++) {
        if (result[i].getName() == 'name') {
          this.name = result[i].getValue()
        }
        if (result[i].getName() == 'nickname') {
          this.nickname = result[i].getValue()
        }
        if (result[i].getName() == 'gender') {
          this.gender = result[i].getValue()
        }
        if (result[i].getName() == 'custom:age') {
          this.age = result[i].getValue()
        }
        if (result[i].getName() == 'locale') {
          this.locale = result[i].getValue()
        }
      }
    //公開範囲情報取得
    axios.get(process.env.VUE_APP_API_GATEWAY + '/api-user', {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.isPrivate = !res.data.isPrivate
        this.checkinOpenRange = res.data.checkinOpenRange
        this.recordOpenRange = res.data.recordOpenRange
        this.profileText = res.data.profileText
        this.$refs.statusImg.src = process.env.VUE_APP_MATERIAL_URL + res.data.iconPath
        this.iconPath = res.data.iconPath
      })
      .catch(err => {
        this.message = 'システムエラー：表示情報を取得できませんでした'
      })                      
    })
  },
  methods: {
    statusUpd() {
      //画像の取得
      axios.get(process.env.VUE_APP_MATERIAL_URL + '/master.json', {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.data = res.data.badge
      })

      this.$bvModal.show('status-modal')
      return
    },
    changeStatus(imgPath) {
      console.log(imgPath)
      this.$refs.statusImg.src = process.env.VUE_APP_MATERIAL_URL + imgPath
      this.iconPath = imgPath
      this.$bvModal.hide('status-modal')
    },
    exec(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      this.nickname = this.nickname.trim()

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }

      //属性の更新
      let attributeList = []

      let dataNickname = {
        Name : 'nickname',
        //空の場合'未設定'
        Value : this.nickname == '' ? '未設定' : this.nickname
      }
      let attributeNickname = new CognitoUserAttribute(dataNickname)
      attributeList.push(attributeNickname)

      let dataGender = {
        Name : 'gender',
        Value : this.gender
      }
      let attributeGender = new CognitoUserAttribute(dataGender)
      attributeList.push(attributeGender)

      let dataAge = {
        Name : 'custom:age',
        Value : this.age
      }
      let attributeAge = new CognitoUserAttribute(dataAge)
      attributeList.push(attributeAge)

      let dataLocale = {
        Name : 'locale',
        Value : this.locale,
      }
      let attributeLocale = new CognitoUserAttribute(dataLocale)
      attributeList.push(attributeLocale)

      this.$user.cognito.updateAttributes(attributeList, (err, result) => {
        if (err) {
          this.errMessage = true
          this.isDisabled = false
          return
        }
        this.$user.cognito.refreshSession(this.$user.refreshToken, (err, result) => {
          if(err) {
            this.errMessage = true
            this.isDisabled = false
            return
          }
          if(!this.isPrivate) {
            this.checkinOpenRange = "CLOSE"
            this.recordOpenRange = "CLOSE"
          }
          //公開有無とチェックイン公開範囲の更新
          const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
          const params = {
            isPrivate: !this.isPrivate,
            checkinOpenRange: this.checkinOpenRange,
            recordOpenRange: this.recordOpenRange,
            name: dataNickname.Value,
            gender: this.gender,
            age: this.age,
            locale: this.locale,
            profileText: this.profileText,
            iconPath: this.iconPath
          }
          //内容送信
          axios.patch(url, params, {
            headers: {
              'Authorization': this.$user.idToken
            }
          })
            .then(res => {
              this.$router.push('/user/edit/complete')
              this.isDisabled = false
            })
            .catch(err => {
              console.log(err)
            })
            })
          })
    }
  },
  components: {
    Pref,
    Age,
  },
}
</script>

<style lang="stylus" scoped>
img 
  border-radius 50%
  height 180px
  width 180px
#status-modal
 img
  border-radius 50%
  height 120px
  width 120px
  &:hover
    cursor pointer
    border: 0.5px solid #3A5263
  &:active
    border: 1.2px solid #3A5263
.css-fukidashi
  padding 0
  margin 0
  position relative
  display inline-block
.text
  background #107dc7
  color #FFF
  position: relative
.fukidashi
  width 200px
  position absolute
  top -95px
  left -105px
  padding 5px
  border-radius 5px
  background #107dc7
  color #fff
  font-weight bold
  &:after
    position: absolute
    width 20px
    height 0
    left 123px
    bottom -19px
    margin-left 0px
    border solid transparent
    border-color rgba(51, 204, 153, 0)
    border-top-color #107dc7
    border-width 10px
    pointer-events none
    content " "
.fade-enter-active, .fade-leave-active 
  transition opacity .5s
.fade-enter, .fade-leave-to 
  opacity 0
</style>