<template lang="pug">
  div
    select.custom-select(:value="value" v-on:input="$emit('input', $event.target.value)" required)
      option(value="未回答" selected) ---未回答---
      option(v-for="item in items" :value="item") {{item}}
</template>
<script>
const AGE = ['0-9歳', '10-19歳', '20-29歳', '30-39歳', '40-49歳', '50-59歳', '60-69歳', '70-79歳']
export default {
  props: {
    value: String
  },
  data() {
    return {
      items: AGE
    }
  }
}
</script>
