var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c(
          "div",
          { staticClass: "col-lg-5" },
          [
            _c("h1", [_vm._v("ユーザー情報の編集")]),
            _vm.message
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.message))
                ])
              : _vm._e(),
            _vm.errMessage
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v("システムエラーが発生しました。"),
                  _c(
                    "div",
                    [
                      _vm._v(
                        "しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが"
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: "/inquiry_form/input" } },
                        [_vm._v("こちら")]
                      ),
                      _vm._v("よりをお問い合わせください。")
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "form",
              {
                staticClass: "needs-validation",
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.exec($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "status" } }, [
                    _vm._v("ステータスバッジ")
                  ]),
                  _c("br"),
                  _c("img", {
                    ref: "statusImg",
                    attrs: { id: "statusImg", src: "", alt: "" },
                    model: {
                      value: _vm.iconPath,
                      callback: function($$v) {
                        _vm.iconPath = $$v
                      },
                      expression: "iconPath"
                    }
                  })
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "nickname" } }, [
                    _vm._v("名前（サイト内の表示 最大20文字）")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nickname,
                        expression: "nickname"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "nickname",
                      type: "text",
                      placeholder: "ヤマ　ラボ太",
                      maxlength: "20",
                      required: ""
                    },
                    domProps: { value: _vm.nickname },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.nickname = $event.target.value
                      }
                    }
                  }),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("お名前を入力してください")
                  ])
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "gender" } }, [_vm._v("性別")]),
                  _c("br"),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "gender1",
                        type: "radio",
                        name: "gender",
                        value: "男性",
                        required: ""
                      },
                      domProps: { checked: _vm._q(_vm.gender, "男性") },
                      on: {
                        change: function($event) {
                          _vm.gender = "男性"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "gender1" }
                      },
                      [_vm._v("男性 ")]
                    )
                  ]),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "gender2",
                        type: "radio",
                        name: "gender",
                        value: "女性",
                        required: ""
                      },
                      domProps: { checked: _vm._q(_vm.gender, "女性") },
                      on: {
                        change: function($event) {
                          _vm.gender = "女性"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "gender2" }
                      },
                      [_vm._v("女性")]
                    )
                  ]),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "gender3",
                        type: "radio",
                        name: "gender",
                        value: "未回答",
                        required: "",
                        checked: ""
                      },
                      domProps: { checked: _vm._q(_vm.gender, "未回答") },
                      on: {
                        change: function($event) {
                          _vm.gender = "未回答"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label radio-label",
                        attrs: { for: "gender3" }
                      },
                      [_vm._v("未回答")]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "age" } }, [_vm._v("年代")]),
                    _c("Age", {
                      model: {
                        value: _vm.age,
                        callback: function($$v) {
                          _vm.age = $$v
                        },
                        expression: "age"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "locale" } }, [
                      _vm._v("都道府県")
                    ]),
                    _c("Pref", {
                      model: {
                        value: _vm.locale,
                        callback: function($$v) {
                          _vm.locale = $$v
                        },
                        expression: "locale"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { attrs: { for: "isPrivate" } }, [
                    _vm._v("ユーザー情報公開"),
                    _c(
                      "span",
                      { staticClass: "css-fukidashi" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text mx-3 px-1",
                            on: {
                              mouseover: function($event) {
                                _vm.show1 = true
                              },
                              mouseleave: function($event) {
                                _vm.show1 = false
                              }
                            }
                          },
                          [_vm._v("？")]
                        ),
                        _c("transition", { attrs: { name: "fade" } }, [
                          _vm.show1
                            ? _c("p", { staticClass: "fukidashi" }, [
                                _vm._v(
                                  "名前、登攀記録、チェックイン状況等の他ユーザーへの公開有無設定"
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "custom-control custom-checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.isPrivate,
                        expression: "isPrivate"
                      }
                    ],
                    staticClass: "custom-control-input",
                    attrs: { id: "isPrivate", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.isPrivate)
                        ? _vm._i(_vm.isPrivate, null) > -1
                        : _vm.isPrivate
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.isPrivate,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.isPrivate = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.isPrivate = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.isPrivate = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "isPrivate" }
                    },
                    [_vm._v("ユーザー情報を公開する")]
                  )
                ]),
                _c("div", { staticClass: "form-group ml-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3 ml-1",
                      attrs: { for: "recordOpenRange" }
                    },
                    [
                      _vm._v("登攀記録の公開範囲"),
                      _c(
                        "span",
                        { staticClass: "css-fukidashi" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text mx-3 px-1",
                              on: {
                                mouseover: function($event) {
                                  _vm.show2 = true
                                },
                                mouseleave: function($event) {
                                  _vm.show2 = false
                                }
                              }
                            },
                            [_vm._v("？")]
                          ),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.show2
                              ? _c("p", { staticClass: "fukidashi" }, [
                                  _vm._v(
                                    "登攀記録(登攀件数、どの岩場を登攀したか)の公開範囲設定"
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline mt-2 ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.recordOpenRange,
                            expression: "recordOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "recordOpenRange1",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "recordOpenRange",
                          value: "OPEN",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.recordOpenRange, "OPEN")
                        },
                        on: {
                          change: function($event) {
                            _vm.recordOpenRange = "OPEN"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "recordOpenRange1" }
                        },
                        [_vm._v("公開 ")]
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.recordOpenRange,
                            expression: "recordOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "recordOpenRange2",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "recordOpenRange",
                          value: "FRIENDS_ONLY",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.recordOpenRange, "FRIENDS_ONLY")
                        },
                        on: {
                          change: function($event) {
                            _vm.recordOpenRange = "FRIENDS_ONLY"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "recordOpenRange2" }
                        },
                        [_vm._v("友達のみ公開")]
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline mb-2 ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.recordOpenRange,
                            expression: "recordOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "recordOpenRange3",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "recordOpenRange",
                          value: "CLOSE",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.recordOpenRange, "CLOSE")
                        },
                        on: {
                          change: function($event) {
                            _vm.recordOpenRange = "CLOSE"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "recordOpenRange3" }
                        },
                        [_vm._v("非公開")]
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group ml-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3 ml-1",
                      attrs: { for: "checkinOpenRange" }
                    },
                    [
                      _vm._v("チェックイン情報の公開範囲"),
                      _c(
                        "span",
                        { staticClass: "css-fukidashi" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text mx-3 px-1",
                              on: {
                                mouseover: function($event) {
                                  _vm.show3 = true
                                },
                                mouseleave: function($event) {
                                  _vm.show3 = false
                                }
                              }
                            },
                            [_vm._v("？")]
                          ),
                          _c("transition", { attrs: { name: "fade" } }, [
                            _vm.show3
                              ? _c("p", { staticClass: "fukidashi" }, [
                                  _vm._v(
                                    "チェックイン情報（リアルタイム情報と履歴情報）の公開範囲設定"
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline mt-2 ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkinOpenRange,
                            expression: "checkinOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "checkinOpenRange1",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "checkinOpenRange",
                          value: "OPEN",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkinOpenRange, "OPEN")
                        },
                        on: {
                          change: function($event) {
                            _vm.checkinOpenRange = "OPEN"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "checkinOpenRange1" }
                        },
                        [_vm._v("公開 ")]
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkinOpenRange,
                            expression: "checkinOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "checkinOpenRange2",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "checkinOpenRange",
                          value: "FRIENDS_ONLY",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkinOpenRange, "FRIENDS_ONLY")
                        },
                        on: {
                          change: function($event) {
                            _vm.checkinOpenRange = "FRIENDS_ONLY"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "checkinOpenRange2" }
                        },
                        [_vm._v("友達のみ公開")]
                      )
                    ]
                  ),
                  _c("br"),
                  _c(
                    "div",
                    { staticClass: "form-check form-check-inline ml-2" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkinOpenRange,
                            expression: "checkinOpenRange"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "checkinOpenRange3",
                          type: "radio",
                          disabled: !_vm.isPrivate,
                          name: "checkinOpenRange",
                          value: "CLOSE",
                          required: ""
                        },
                        domProps: {
                          checked: _vm._q(_vm.checkinOpenRange, "CLOSE")
                        },
                        on: {
                          change: function($event) {
                            _vm.checkinOpenRange = "CLOSE"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label radio-label",
                          attrs: { for: "checkinOpenRange3" }
                        },
                        [_vm._v("非公開")]
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "Inquiry" } }, [
                    _vm._v("自己紹介")
                  ]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profileText,
                        expression: "profileText"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "Inquiry",
                      type: "Inquiry",
                      rows: "5",
                      maxlength: "150"
                    },
                    domProps: { value: _vm.profileText },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.profileText = $event.target.value
                      }
                    }
                  })
                ]),
                _c("input", {
                  staticClass: "btn btn-primary",
                  attrs: {
                    type: "submit",
                    value: "上記の内容で更新する",
                    disabled: _vm.isDisabled
                  }
                }),
                _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/unsubscribe/consent" } },
                      [_vm._v("ヤマラボを退会する")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "status-modal",
                  title: "アイコンを選択してください。",
                  "hide-footer": ""
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  _vm._l(_vm.data, function(item) {
                    return _c("img", {
                      staticClass: "m-1",
                      attrs: { src: _vm.materialURL + item.imgPath },
                      on: {
                        click: function($event) {
                          return _vm.changeStatus(item.imgPath)
                        }
                      }
                    })
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }